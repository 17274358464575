<template>
  <article class="main main--internal" v-if="article" lang="ru">
    <div class="page-header__container">
      <h1 class="page-header">{{ article.title }}</h1>
    </div>
    <div class="article">
      <div class="article__block">
        <div class="article__content">
          <EditorJSComponent :text="article.text"/>
        </div>
      </div>
    </div>
  </article>
  <main class="main" v-else>
    <section class="main__about">
      <h1>Страница не найдена</h1>
    </section>
  </main>
</template>

<script>
import EditorJSComponent from 'components/EditorJSComponent.vue'

export default {
  name: 'Article',
  components: { EditorJSComponent },
  async asyncData ({ store, route, res }) {
    await store.dispatch('GET_ARTICLE_PAGE', route.params.id)
    if (!store.state.article_page || !store.state.article_page.article) {
      if (res) {
        res.status(404)
      }
    }
  },
  computed: {
    article () {
      return this.$store.state.article_page && this.$store.state.article_page.article ? this.$store.state.article_page.article : null
    }
  },
  metaInfo () {
    const article = this.$store.state.article_page && this.$store.state.article_page.article ? this.$store.state.article_page.article : null
    return this.$seo(
      'common',
      article ? article.meta_title : 'Страница не найдена',
      article ? article.meta_keywords : 'Страница не найдена',
      article ? article.meta_description : 'Страница не найдена',
      article ? article.meta_title : 'Страница не найдена',
      article ? this.$store.state.api + article.image : null,
      article ? article.meta_description : 'Страница не найдена'
    )
  }
}
</script>
